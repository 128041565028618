<template>
    <div class="flex flex-col flex-1 max-h-full overflow-y-auto" v-allow:auth="'block.read'">
        <SubHeader :showOnlyCustomCrumbs="true" :customCrumbLists="customBreadCrumbs" :handleClick="showModal" buttonText="Add Block" :showBtn="checkPermission('block.create')" />
        <Table
            id="blockTable"
            :columns="columnOptions"
            :key="pageData?.total"
            @handleClone="handleCloneBlock"
            :handleCaseClick="handleClickBlock"
            :rows="processData(rows)"
            :isLoading="loadingStates.blockList"
            @per-page-change="onPageChange($event, true)"
            @page-change="onPageChange($event)"
            :paginationOptions="paginationOptions"
            :sort-options="{
                enabled: true,
            }"
            sortingEnabled
            @sort-change="onSortChanged"
            :totalRows="pageData?.total"
            @filters-clear="clearFilters"
            @column-filter="onColumnFilters($event)"
            @search-filter="getSearchOptionsTick($event)"
            :filterOptionsAsync="columnFilterOptionsAsync"
            :filterOptions="filterOptions"
            :filters="serverParams"
            
        >
            <template #link="customData">
                <div class="truncate max-w-full whitespace-nowrap cursor-pointer">
                <span v-tippy :content="customData.customData.name">
                    {{ customData.customData.name }}
                </span>
                </div>
            </template>
            <template #show_info="customData">
                <button :disabled="!checkPermission('check.read')" :class="(customData.customData && customData.customData.check_count > 0 && checkPermission('check.read')) ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer' : 'text-gray-500 cursor-not-allowed' " @click="setFieldChecks(customData.customData)">used in {{ customData.customData.check_count }} checks</button>
            </template>
            <template #actions="customData">
                    <div class="flex justify-around">
                        <button class="h-6 w-4 mx-2 mt-1 rounded-full disabled:opacity-50" @click="handleCloneBlock(customData.customData)" v-tippy :content="'Clone'" :disabled="!checkPermission('block.edit') && !checkPermission('block.create')">
                        <font-awesome-icon icon="clone" class="text-blue-600 text-lg fa-2x" :class="(customData.customData) ? 'text-primary hover:text-dvbrandhoveron hoverBg hover:underline cursor-pointer' : 'text-gray-500' "/>
                    </button>
                    <router-link 
                            :to="customData.customData.lock ? {} : { name: 'block-admin-details', params: { id: customData.customData.id , action: 'edit'} }" class="text-primary truncate max-w-full whitespace-nowrap cursor-pointer hover:text-primary">
                        <button class="h-8 w-4 disabled:opacity-50 mx-2" :disabled="customData.customData.lock == true || !checkPermission('block.edit')" v-tippy :content="'Edit'">
                                <font-awesome-icon icon="edit" class="text-blue-600 text-lg fa-3x" :class="(customData.customData) ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer' : 'text-gray-500' "/>
                            </button>
                    </router-link>
                    <router-link 
                            :to="{ name: 'block-admin-details', params: { id: customData.customData.id , action: 'view'},}" class="text-primary truncate max-w-full whitespace-nowrap cursor-pointer hover:text-primary">
                        <button class="h-8 w-4 disabled:opacity-50 mx-2" v-tippy :content="'View'" :disabled="!checkPermission('block.read')">
                            <font-awesome-icon icon="eye" class="text-blue-600 text-lg fa-3x" :class="(customData.customData) ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer' : 'text-gray-500' "/>
                            </button>
                    </router-link> 
                    </div>
                </template>
        </Table>
        <AddBlockModal ref="block-admin-add-block" :isLoading="loadingStates.addingBlock" @createNewBlock="addNewBlock($event)" />
        <LoaderFull v-if="loadingStates.isCloning" loadingText="Cloning Block..." />
        <FieldChecksModal @closed="setFieldChecks(null)" :field="getFieldChecks" @handleFilterResult="handleFilterResult($event, 'check')" />
    </div>
</template>

<script>
import Table from "@shared/dashboard-components/table-large";
import SubHeader from "@/components/SubHeader";
import AddBlockModal from "../components/blockModal.vue";
import axios from "@/axios";
import LoaderFull from "@/components/loader-full";
import FieldChecksModal from "../../field-table/components/fieldChecksModal.vue";
import { onlyUnique } from "@/plugins/utils.js";
import { checkPermission } from "@shared/utils/functions"
import { EventBus } from "@/main.js";
import { DateTime } from "luxon";
import { FormatDateNew } from "@/plugins/functions"

export default {
    name: "block-admin-list",
    components: {
        Table,
        SubHeader,
        AddBlockModal,
        LoaderFull,
        FieldChecksModal,
    },
    props: {},
    data() {
        return {
            customBreadCrumbs: [{ name: "Admin" }, { name: "Block List" }],
            // Remove Dummy Data After API Integration
            columns: [
                {
                    label: "Action",
                    field: "actions",
                    config: { filter: false, type: "actions" },
                    sortable: false
                },
                { label: "Lock", field: "lock", config: { filter: false, type: "lock" , tab:"Block in use" }, tdClass: "truncate max-w-xs" , sortable: false},
                { label: "In Use", field: "in_use", config: { filter: false, type: "tooltip_text" }, tdClass: "truncate max-w-xs" },
                {
                    label: "Name",
                    field: "name",
                    config: {
                        type: "link",
                        filter: true,
                    },
                    tdClass: 'truncate max-w-xs',
                },
                {
                    label: "Used in checks",
                    field: "check_count",
                    sortable: false,
                    config: {
                        filter: false,
                        type: "show_info",
                    },
                    tdClass: 'truncate max-w-xs'
                },
                {
                    label: "Label",
                    field: "label",
                    config: {
                        filter: true,
                        type: 'tooltip_text',
                    },
                    tdClass: 'truncate max-w-xs'
                },
                {
                    label: "Description",
                    field: "description",
                    config: {
                        filter: false,
                    },
                    tdClass: 'truncate max-w-xs'
                },
                { label: "Created By", field: "created_by", config: { filter: false, type: 'tooltip_text' }, tdClass: "truncate max-w-xs" },
                { label: "Created On", field: "created_at", config: { filter: true, type: 'tooltip_text' }, tdClass: "truncate max-w-xs" },
                { label: "Last Updated By", field: "modified_by", config: { filter: false, type: 'tooltip_text' }, tdClass: "truncate max-w-xs" },
                { label: "Last Updated On", field: "updated_at", config: { filter: true, type: 'tooltip_text' }, tdClass: "truncate max-w-xs" },
            ],
            rows: [],
            loadingStates: {
                blockList: false,
                addingBlock: false,
                isCloning: false,
            },
            fieldChecks: null,
            paginationOptions: {
                enabled: true,
                mode: "remote",
                perPage: 10
            },
            pageData: null,
            currentPageData: {
                page: 1,
                count: 10,
            },
            serverParams: null,
            columnFilterOptionsAsync: {},
            searchTimeout: 500,
            searchLoading: {},
            searchParams:{
                check: ""
            },
            sortParams: null
        };
    },
    async created() {
        EventBus.$on("fetchBlockLists", () => {
             this.fetchBlockLists()
        });
    },
    computed: {
        filterOptions() {
            let result =  this.getFilterOptions(
                this.columns,
                this.rows
            )
            return result
        },
        columnOptions() {
            return this.columns.map(el => ({...el, config:{ ...el.config, isLoading: !!this.searchLoading[el.field] || false}}))
        },

        getTypeOptions() {
            let items =  this.rows.map(el => ({...el, id: el.field_type_id, name: el.ui_label}))
            return items
        },
        getFieldChecks(){
            return {...this.fieldChecks, checks:this.fieldChecks?.checks.filter(el => (Object.entries(el)[0][1]?.toLowerCase()?.includes(this.searchParams.check.toLowerCase())))}
        }
    },
    async mounted() {
        await this.fetchBlockLists();
    },
    methods: {
        FormatDateNew,
        checkPermission,
        async addNewBlock(blockData) {
            this.loadingStates.addingBlock = true;
            try {
                let url = `/blocks`;
                let { data } = await axios.post(url, blockData);
                this.$toast.success(data.message || "Block created");
                this.rows.push({ ...blockData, id: data.block_id });
                this.$router.push({
                    name: "block-admin-details",
                    params: { id: data.block_id , action : "new"},
                });
                this.$refs["block-admin-add-block"].closeBlockModal();
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to create block");
            }

            this.loadingStates.addingBlock = false;
        },
        showModal() {
            this.$refs["block-admin-add-block"].showBlockModal();
        },
        handleClickBlock(data) {
            this.$router.push({
                name: "block-admin-details",
                params: { id: data.id },
            });
        },
        processData(row){
            for(let data in row){
                if(row[data].label){
                    if(row[data].label.length>100){
                        row[data].label=row[data].label.substring(0,97)+'...'
                    }
                }
            }
            return row;
        },
        // handleCloneField(row) {
        //     this.rows.push(row);
        // },
        async fetchBlockLists() {
            this.loadingStates.blockList = true;
            try {
                let qParams = new URLSearchParams(this.currentPageData);
                let filterParams = {};
                if (this.serverParams) {
                    Object.keys(this.serverParams).map((key) => {
                        filterParams[key] = this.serverParams[key] ? this.serverParams[key]["name"] : delete filterParams[key];
                    });
                if(filterParams.created_at){
                    filterParams.created_at= DateTime.fromFormat(filterParams.created_at, "dd MMM yyyy").plus({ days: 0 }).toISODate();
                }
                if(filterParams.updated_at){
                    filterParams.updated_at= DateTime.fromFormat(filterParams.updated_at, "dd MMM yyyy").plus({ days: 0 }).toISODate();
                }
                }
                let url = `/blocks?${qParams}`;
                let { data } = await axios.get(url, { params: { ...filterParams, ...this.sortParams } });
                // const formatDate = (date) => (date ? DateTime.fromISO(date).setZone('UTC').toFormat("dd MMM yyyy") : "");
                data.data = data.data?.map((item) => {
                    if(item.created_at || item.updated_at) {
                        item.created_at = FormatDateNew(new Date(item.created_at));
                        item.updated_at = FormatDateNew(new Date(item.updated_at));
                        return item;
                    }
                })
                this.rows = data.data || [];
                this.pageData = data?.page;
            } catch (error) {
                console.log(error, ">>>>>error");
            }
            this.loadingStates.blockList = false;
        },
        async handleCloneBlock(block) {
            if(this.checkPermission('block.create')){
                this.loadingStates.isCloning = true;
                try {
                    let url = `/blocks/${block.id}/clone`;
                    let { data } = await axios.get(url);
                    this.$router.push({
                        name: "block-admin-details",
                        params: { id: data.block_id ,action:"new"},
                    });
                    this.$toast.success(data.message || "Block Cloned successfully");
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to clone block");
                }
                this.loadingStates.isCloning = false;
            // }else{
            //     this.$toast.error('Access Denied! You are not authorized to perform this operation.')
            }
            
        },
        setFieldChecks(payload) {
            if(payload && payload.check_count > 0){
                this.fieldChecks = payload;
                this.$modal.show("fieldChecksModal");
            }
        },
        async onPageChange(data, resetPage = false) {
            this.currentPageData = {
                page: data.currentPage,
                count: data.currentPerPage,
            };
            if (resetPage) this.currentPageData.page = 1;
            if(!resetPage) {
                await this.$nextTick();
                const scrollTarget = document.getElementById('blockTable');
                if (scrollTarget) {
                  scrollTarget.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
            }
            await this.fetchBlockLists();
        },
        // Filter table

        async onColumnFilters({ columnFilters }) {
            this.currentPageData.page = 1
            this.paginationOptions.perPage = this.currentPageData.count;
            this.serverParams = columnFilters ? { ...columnFilters } : null;
            await this.fetchBlockLists();
        },
        async onSortChanged(data) {
            if (data && data.length) {
                data = data[0]
                this.sortParams = {
                sort: data.field + ':' + data.type
                },
                await this.fetchBlockLists()
            }
        },
        async clearFilters() {
            this.paginationOptions.perPage = this.currentPageData.count
            this.serverParams = null;
            await this.fetchBlockLists();
        },
        getFilterOptions(columns, rows) {
            let cols = columns.reduce((result, curr) => {
                result[curr.field] = rows
                    .filter((row) => row[curr.field])
                    .map((row) => ({ id: row.id, name: row[curr.field] }))
                    .filter(onlyUnique);
                // .map((el) => ({ id: el.field_type_id ? el.field_type_id :  uuid.v4(), name: el }));
                return result;
            }, {});
            return cols;
        },

        getSearchOptionsTick(payload) {
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(payload);
            }, delay);
        },

        async getSearchOptions(payload) {
            // if searching is canceled reset async filter options for the field:
            if (!payload.value) this.columnFilterOptionsAsync = null;
            else {
                // set 'loading' prop for the column we are searching in:
                this.$set(this.searchLoading, payload.column.field, true);
                try {
                    const requestData = {
                        ...this.serverParams,
                        [payload.column.query_key || payload.column.field]: payload.value,
                    };
                    const { data } = await axios.get(`/blocks`, {
                        params: requestData,
                    });
                    // set async options:
                    // const columns = {
                    //   check: this.cardData.tableLarge.checkView.columns,
                    //   candidate: this.cardData.tableLarge.candidateView.columns,
                    // };
                    this.columnFilterOptionsAsync = this.getFilterOptions(this.columnOptions, data.data);
                } catch (error) {
                    console.log("error :>> ", error);
                }

                this.$set(this.searchLoading, payload.column.field, false);
            }
        },
        handleFilterResult(data, type){
            this.searchParams[type] = data
        }

        //   End
    },
};
</script>

<style lang="scss" scoped>
.hoverBg {
    &:hover {
        color: var(--theme-color-main)
    } 
}
</style>
